<template>
  <div class="container-fluid">
    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7" class="position-rel" style="z-index: 2;">
          <Card
            title="Localização e organização administrativa territorial"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                O distrito de Santa Rita Durão pertence ao município de
                Mariana-MG e possui
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_bento_rodrigues' }"
                  >Bento Rodrigues</router-link
                >
                e Alegria como subdistritos. Situa-se a 35 km do centro de
                Mariana e a 150 km de Belo Horizonte, capital do estado de Minas
                Gerais. O acesso ocorria de duas formas, utilizando-se caminhos
                de ligação entre a sede de Mariana e o município de Santa
                Bárbara. Um deles, através da Estrada Real, passava por Camargos
                e Bento Rodrigues (estrada municipal ARM-130), com 32 km de
                extensão a partir de Mariana até Santa Bárbara, sem
                pavimentação. Outra alternativa seria seguir pela MG-129,
                rodovia asfaltada que liga Mariana a Santa Bárbara, com 45 km de
                distância. No entanto, com a completa destruição da comunidade
                de Bento Rodrigues pela lama de rejeitos, resta somente o
                segundo caminho, mais distante e com trânsito intenso de
                veículos, principalmente de atividades mineradoras.
              </p>
            </template>
          </Card>
        </v-col>

        <v-img
          class="position-abs"
          max-width="100%"
          src="../../assets/sobre_territorio_paracatu_01.png"
        />
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5">
          <v-img src="../../assets/sobre_territorio_santa_rita_01.png"></v-img>
        </v-col>
        <v-col cols="12" md="7" class="position-rel" style="z-index: 2;">
          <Card
            title="Formação do povoamento"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Na passagem do século XVII para o XVIII, foi se formando o
                povoamento próximo à Serra do Caraça, na bacia do Rio
                Piracicaba. A ocupação do local foi motivada pela descoberta de
                ouro pelo bandeirante paulista Salvador Faria de Albernás, que
                explorava o Ribeirão do Carmo, ao longo do qual se solidificou a
                sede do município de Mariana.<sup>1</sup>
              </p>
              <p>
                Outros povoamentos como Passagem de Mariana, São Sebastião
                (atual distrito de Bandeirantes), São Caetano (atual distrito de
                Monsenhor Horta), Furquim e Acaiaca também se originaram em
                locais percorridos pelo Ribeirão do Carmo. No Rio Gualaxo do
                Sul, ainda no século XVIII, surgiram núcleos urbanos como
                Cachoeira do Brumado, Mainard e Vargem. Às margens do Rio
                Gualaxo do Norte, surgiram as localidades de Antônio Pereira
                (região onde nasce o curso d’água),
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_camargos' }"
                  >Camargos</router-link
                >, Bento Rodrigues, Gama, Teixeira (atual subdistrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu' }"
                  >Paracatu</router-link
                >) e São José de Matias Barbosa (atual município de Barra
                Longa). Outros povoados se formaram em terras mais distantes, ao
                norte do Rio Gualaxo do Norte, como Inficcionado (atual Santa
                Rita Durão), Paulo Moreira (atual município de Alvinópolis) e
                Águas Claras do Lobo (atual distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >).
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5">
          <v-img src="../../assets/sobre_territorio_santa_rita_02.png"></v-img>
        </v-col>
        <v-col cols="12" md="5" style="z-index: 2;" class="ml-md-8">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Inicialmente, a localidade onde depois se estabeleceu Santa Rita
                Durão foi conhecida como Arraial do Inficcionado, em função do
                pouco ouro e de baixo teor encontrado nas águas do Rio
                Piracicaba, nas margens do qual o povoamento se formou. Na
                época, a Fazenda Cata Preta era a principal referência do
                arraial e seu proprietário, o sargento-mor de milícias urbanas,
                Paulo Rodrigues Durão, foi o responsável pela construção da
                capela que logo se tornou a Igreja Matriz. Em 1718, o arraial
                foi elevado à condição de freguesia de Nossa Senhora de Nazaré
                do Inficcionado e, em 1752, tornou-se uma paróquia civil. Foi a
                partir dessa época que a localidade experimentou seu momento de
                ascensão e posterior retração econômica. No ano de 1895, o
                povoamento, então estabelecido como distrito do município de
                Mariana, recebeu seu nome definitivo, em homenagem a José de
                Santa Rita Durão, filho de Paulo Rodrigues Durão.
              </p>
              <p>
                Assim como em outros núcleos coloniais, os templos religiosos da
                localidade sobressaíam-se na paisagem, além de serem resistentes
                ao passar do tempo e apresentarem aparência sofisticada. Além da
                igreja Matriz, também encontra-se edificada em altitude mais
                elevada em relação à área onde se implantaram as casas do núcleo
                urbano inicial, a igreja do Rosário, às margens da antiga
                estrada principal, atual Rua do Rosário. O arruamento é em parte
                calçado por seixos rolados e em parte por terra batida. Outras
                características coloniais são observadas no alinhamento do
                casario definindo as vias, composto por unidades térreas e
                assobradadas, construídas em terrenos com extensos quintais e
                cercados por muros de pedra em meio à vegetação abundante.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>2</sup> </strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização das comunidades tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.brown__stripe {
  position: relative;
}

.brown__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 200%;
  background-color: #cd6b3e;
}
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
  left: 53%;
  top: 8%;
  opacity: 0.4;
  z-index: 1;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  .position-abs {
    position: relative;
    left: 0;
    top: 0;
    opacity: 0.4;
    z-index: 1;
  }
}
</style>
